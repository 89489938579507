import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <h2>404</h2>
        <p>Sidan du försökt navigera till finns inte.</p>
      </div>
    );
  }
}
