import React, { Component } from 'react';

export default class ApplyForMembership extends Component {
  render() {
    return (
      <div>
        <h2>Ansökan</h2>
        <p>Ansök om medlemskap genom att...</p>
      </div>
    );
  }
}
