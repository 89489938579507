import React, { Component } from 'react';

export default class Information extends Component {
  render() {
    return (
      <div>
        <h2>Intern information</h2>
        <p>Information för en inloggad medlem.</p>
      </div>
    );
  }
}
